import React from 'react'

function SelectPolicyTypePopup({setCurrentPopup}) {
  return (
    <div className='background-color-full-dark border-radius-10px popup ' id = "select-policy-type-popup" >
<div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)} > ❌</button></div>
  <div className='flex-container-el padding-20px'> 
    <h3 className='text-align-left margin-bottom-10'>Select Policy Type</h3>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(3)}>Automobile</button>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(2)}>Motorcycle</button>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(4)}>Jewelry</button>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(5)}>Home</button>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(10)}>Rental</button>
    <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(11)}>PUP</button>
  </div>
</div>
  )
}

export default SelectPolicyTypePopup

