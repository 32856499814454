import React, { useEffect, useRef, useState, forwardRef } from 'react';

const PlacesAutocomplete = ({ id, setCityVal, cityVal, stateVal, setStateVal,setPostCodeVal }) => {
  const inputRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [place, setPlace] = useState(null);

  useEffect(() => {
    const existingScript = document.getElementById('googleMaps');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvYosylzmUOo4gZGfHG090rjVAiDJN0TQ&libraries=places&callback=initAutocomplete`;
      script.id = 'googleMaps';
      script.async = true;
      document.body.appendChild(script);

      window.initAutocomplete = () => {
        setScriptLoaded(true);
      };

      script.onerror = () => {
        console.error("Google Maps script could not be loaded.");
      };
    } else {
      setScriptLoaded(true);
    }

    return () => {
      const script = document.getElementById('googleMaps');
      if (script) {
        script.remove();
      }
      delete window.initAutocomplete;
    };
  }, []);

  useEffect(() => {
    if (scriptLoaded && window.google && window.google.maps) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['geocode'],
      });

      autocomplete.addListener('place_changed', () => {
        setPlace(autocomplete.getPlace().address_components)
        if(autocomplete.getPlace().address_components.length == 7 ){ 
          setCityVal(autocomplete.getPlace().address_components[2]?.long_name) 
          setStateVal(autocomplete.getPlace().address_components[4]?.long_name)
          setPostCodeVal(autocomplete.getPlace().address_components[6]?.long_name)

        }else if(autocomplete.getPlace().address_components.length == 9 ){ 
          setCityVal(autocomplete.getPlace().address_components[3]?.long_name) 
          setStateVal(autocomplete.getPlace().address_components[5]?.long_name)
          setPostCodeVal(autocomplete.getPlace().address_components[7]?.long_name)
        }

      });
    }
  }, [scriptLoaded]);

  const checkIfInputEmpty = (e) => { 
    if(!e.target.value){ 
      setCityVal(null)
      setStateVal(null)
      setPostCodeVal(null)
    }
  }

  return (
    <>
      
      <input
        className="background-color-full-dark regularTextInput margin-top-5px new-household-input input width-380px"
        id={id}
        ref={inputRef}
        type="text"
        placeholder="Enter a location"
        value = {null ? "" : cityVal ? cityVal : stateVal}
        onChange={checkIfInputEmpty}
      />
    </>
  );
};

export default PlacesAutocomplete;
