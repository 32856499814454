import React, { useEffect, useState, useRef } from 'react';
import Calender_popup from './calender_popup/Calender_popup';
import Cookies from 'js-cookie';
let api = require('./apiConfig');
api = api.api;
const jwt = Cookies.get('jwt');




function Leftside({ setSearchTerm,  setSwitchNumber, setCurrentHouseholdId, getTasks, tasks, recentCustomers }) {
  const [latestDrivers, setLatestDrivers] = useState(null);
  const searchTermValue = useRef(null);

  const reversed = [... new Set(recentCustomers)].slice().reverse();


  async function fetchHouseholdsBelongingToUser() {
    const response = await fetch(`${api}/api/latestdrivers?amount=10&user_id=${Cookies.get('userid')}`, {
      headers: {
        Authorization: jwt,
      },
    });
    const data = await response.json();
    setLatestDrivers(data);
    
    
  }





  useEffect(() => {
    fetchHouseholdsBelongingToUser();
    getTasks();
   
    
    
  }, []);


  const changeCurrentHouseholdId = (id) => {
    setCurrentHouseholdId(id)
    Cookies.set('currentHouseholdId' , id)
    
  }
  const renderSearchResults= () => { 
    setSearchTerm(searchTermValue.current.value);
    setSwitchNumber(1);
 
  }

  

  return (
<>
   
   <div className = "flex-space-between ">

   <div className = "light-dark-container " id = "upcoming-assignments">
    <h3 className= "light-dark-container">Search customer</h3> 
   <input type = "text" className=' width-170px regularTextInput background-light-container' id = 'search-driver-input' ref ={searchTermValue} />
    <button type = "button" class = " margin-left-5 button-default green-submit-btn " id = "left_panel_search_button " onClick={()=> renderSearchResults()}>Search 🔍</button>
   </div>
    
   <div className = "light-dark-container margin-top-50 stretch" id = "recent-customers">
   <h3 className= "light-dark-container">Recent Customers</h3> 
   <div className = "  light-dark-container">
    {recentCustomers? reversed.slice(0,10).map((recentCustomers) => <li className ="light-dark-container display-block block-display cursor-pointer hover" onClick={() => {changeCurrentHouseholdId(recentCustomers.household_id); setSwitchNumber(0);}}>{recentCustomers?.firstname + ' ' + recentCustomers?.lastname }</li> ) : <a href ="#" className ="light-dark-container">No drivers to display</a> }
    </div>
   </div>

   <div className = "light-dark-container margin-top-50 stretch overflow-height  " id = "upcoming-assignments">
    <h3 className= "light-dark-container  ">Upcoming appointments</h3> 
    {tasks && tasks.map(task => ( 
      <>
    <li className ="light-dark-container display-block block-display cursor-pointer hover border-box-ua "  title={"Appointment date: " +  `${task.task_date}`  + "\n"  +  "Description : " + `${task.task_desc}`} onClick = {() => {Cookies.set('currentHouseholdId', task.household_id); window.location.reload() }}>{task.task_desc + " - " + new Date(task.task_date).toLocaleDateString('en-US', {  month: 'long', day: 'numeric', year: 'numeric'})} </li>
    </>
    ))}
    
   </div>
   </div>
    </>
  );
}

export default Leftside;