const{api} = require('../apiConfig.js')

const postAnnouncement = async(announcement) => { 
    const response = await fetch(`${api}/api/announcement`,{
        method: "POST",
        body: JSON.stringify({ 
            'announcement' : announcement
        }), 
        headers: { 
            'Content-Type': 'application/json'
        }
    })
    return  response;
}

const getAnnouncement = async() => { 
    const response = await fetch(`${api}/api/announcement`, { 
        method: "GET"
    })
    return response;
}

const getCount = async() => { 
    const response = await fetch(`${api}/api/driver/count`, { 
        method: "GET"
    })
    return response;
}

const getFollowUps = async(user_id) => { 
    let response;
    if(user_id){ 
         response = await fetch(`${api}/api/household/follow_ups?user_id=${user_id}`, { 
            method: "GET", 
        })
    }else { 
        response = await fetch(`${api}/api/household/follow_ups`, { 
            method: "GET", 
        })
    }

    return response;
}
module.exports = { 
    postAnnouncement, 
    getAnnouncement, 
    getCount, 
    getFollowUps
}

