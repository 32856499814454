import React from 'react'

function Sidebar({setSwitchNumber, currentUserData, setToggleAddAnnouncement }) {

  let user = currentUserData[0];
  return (
   <>
   <div className='flex-column-no-ho-centered bg-color-black-1 max-height-sb'> 
   
        <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(0)}>Home </span>
        <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(7)}>Dashboard </span>
        <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(1)}>Leads </span>
        <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(2)}> Pipeline </span>
        {user &&  user.role === "Admin"  ?  <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(3)}>User Management</span> : null} 
        <span className = "padding-10px cursor-pointer fade-in bg-color-black-1">Work Station</span>
        <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(5)}>AgentZ Resources</span>
        {user && user.role === "Admin" ? <span className = "padding-10px cursor-pointer fade-in bg-color-black-1">AgentZ Management</span> : null }
        <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(4)}>Tech Support</span>
        <span className = "padding-10px cursor-pointer fade-in bg-color-black-1">Providers</span>
        <span className = "padding-10px cursor-pointer fade-in bg-color-black-1">Charts</span>
        <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(6)}>Calendar</span>
        {user && user.role === "Admin" ? <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setToggleAddAnnouncement(true)}>Add Announcement</span> : null}
    </div>
    </>
  )
}

export default Sidebar
