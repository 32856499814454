import React, {useRef} from 'react'
import Cookies from 'js-cookie'

function NewTasks({setCurrentPopup, api, getTasks}) {
  const task_desc = useRef(null)
  const task_date = useRef(null)

  const postTasks = async() => { 
    const response = await fetch(`${api}/api/task`, { 
      method: "POST",
      headers: { 
        "Content-Type": "application/json"
      }, 
      body: JSON.stringify({
        user_id: Cookies.get('userid'),
        task_desc: task_desc.current.value, 
        household_id: Cookies.get('currentHouseholdId'), 
        task_date: task_date.current.value

      })
    })
    return response;
  }

  const newTaskBtn = async() => {
    if(task_desc.current.value && task_date.current.value){
      const response = await postTasks();
      if(response.status === 200){ 
        await getTasks();
        return setCurrentPopup(null)
      }
      return alert("Error creating new task")
      }
      alert('Missing input field')
  } 

  return (
    <>
        <div className='background-color-full-dark border-radius-10px popup '  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)}  >❌</button></div>
            <div className='flex-container-el padding-20px'> 
                <h3 className='text-align-left'> New Task</h3>
                <input type = 'datetime-local' className='max-width' ref = {task_date}></input>
                <textarea className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width margin-top-10px'  autocomplete="off"  ref= {task_desc}/>
                <button type = "button" class = "button-default green-submit-btn margin-top-20px max-width" onClick={() => newTaskBtn()}>Add New Task</button>
        </div>
      </div>
    </>
  )
}

export default NewTasks
