import {React, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { io } from 'socket.io-client';
import Login from './Login'
import ProtectedRoute from './ProtectedRoute'
import './leftside.css'
import './leadspagegrid.css'
import './reuseables.css'
import './rightside.css'
import './middlestyles.css';
import './popups.css';
import Question_Bank from './resourceTabs/question_bank';
import Question_bank_setting from './resourceTabs/question_bank_setting';
function App() {


  useEffect(() => { 
    document.title = "Agentz";
    return () => { 
      document.title ="Agentz";
    }
  })

  return (
    <Router>
     <Routes>
       <Route path="/"  element={<Login/>} />
       <Route path="/lead-management"  element={<ProtectedRoute/>} />
       <Route path="/question-bank"  element={<Question_Bank/>} />
       <Route path="/question-bank/new"  element={<Question_bank_setting/>} />
    </Routes>
  </Router>
  );
}

export default App;