import {React, useRef} from 'react'

function EditDocumentPopup({documentData, setToggleDoc, api, fetchDocs, currentDocID}) {

  const document_name_ref = useRef();
  const document_desc_ref = useRef();
  const document_type_ref = useRef()

  const editDocs = async() => { 
    let document_name = currentDocID; 
    let document_desc_input = document_desc_ref.current.value; 
    let document_type_input = document_type_ref.current.value;

  

    const response = await fetch(`${api}/api/update_entry`, { 
        method: "POST", 
        body: JSON.stringify({ document_name, document_desc_input, document_type_input}), 
        headers: {
            'Content-Type': 'application/json' 
        }
    })
    if(!response.ok){ 
        alert("Error updating document"); 
    } else { 
        fetchDocs();
        setToggleDoc(false);
        

    }
}


  return (
  <div className='background-color-full-dark border-radius-10px popup' >
    <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setToggleDoc(null)} >❌</button></div>
      <div className='flex-container-el padding-20px'> 
      <h3 className='text-align-left margin-bottom-10'>Edit Document</h3>
    
      <label for = "lastname" className='background-color-full-dark text-align-left   margin-top-5px '>Document Description</label>
      <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width  ' ref={document_desc_ref} autocomplete="off"/>
      <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Document type</label>
      <select  className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width  ' ref={document_type_ref} autocomplete="off">
        <option value = "Additional Docs">Additional Docs</option>
        <option value = "Endorsements">Endorsements</option>
        <option value = "Proof Of Home Ownerhsip">Proof Of Home Ownership</option>
        <option value = "Drivers License">Drivers License</option>
        <option value = "FL DL Check">Fl Dl Check</option>
        <option value = "FL DL Check">New Business</option>
      </select>

      <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width " onClick = {() => editDocs()}>Edit Document</button>
      </div>
  </div>
  )
}

export default EditDocumentPopup


