import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';

import OldLeads from './oldLeads';
import FollowUps from './followUps';

const jwt = Cookies.get('jwt');
const user_id = Cookies.get('userid');

function ChartTime({ api, currentUserData }) {
  const [leads, setLeads] = useState(null);
  const [newLeads, setNewLeads] = useState(null);
  const [i, setI] = useState(0);
  const [j, setJ] = useState(0);
  const toggleLeadsPopupRef = useRef(null);

  useEffect(() => {
    
      fetchUnassignedLeads(api);
      fetchUnassignedLeadsNew(api)
    
    
  }, [api]);


  const fetchUnassignedLeadsNew = async (api) => {
    const response = await fetch(`${api}/api/household/newLeads`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const data = await response.json();
    setNewLeads(data);

    if (toggleLeadsPopupRef.current) {
      toggleLeadsPopupRef.current.hidden = false;
    }

    if (data && data.length > 0) {
      setJ(Math.floor(Math.random() * data.length));
    }
  };

  

  const fetchUnassignedLeads = async (api) => {
    const response = await fetch(`${api}/api/household/unassigned`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const data = await response.json();
    setLeads(data);

    if (toggleLeadsPopupRef.current) {
      toggleLeadsPopupRef.current.hidden = false;
    }

  
  };

  const acceptLead = async(household_id_obj) => { 
    const household_id = household_id_obj.innerText;

    const response = await fetch(`${api}/api/household/assign?user_id=${user_id}&household_id=${household_id}`, { 
        method : "POST"
    })

    if(!response.ok){ 
        alert("Error claiming lead");
    } 
    Cookies.set('currentHouseholdId', household_id);
    window.location.reload();

    
    
  }
  
  /*const address = [
    newLeads[j]?.address_line_one, newLeads[j]?.address_line_two,  newLeads[j]?.city, newLeads[j]?.zip_code
  ] */


  return (
    <>
    
      <div className='text-align-center'  ref={toggleLeadsPopupRef} id="toggleLeadsPopup">
        {console.log(j)}
        {newLeads ? newLeads.length > 0 && (
          <div className="flex-column-no-ho-centered light-dark-container text-align-center">
            <h2 className=' light-dark-container '>New Leads</h2>
            <label className='light-dark-container'>Address: {newLeads[j]?.address_line_one ? newLeads[j]?.address_line_one : ""  + ", " + newLeads[j]?.address_line_two ? newLeads[j]?.address_line_two : "" + ", " + newLeads[j]?.city + ", " + newLeads[j]?.zip_code}</label>
            <label className='light-dark-container'>Date created: {new Date(newLeads[j]?.date_created).toLocaleString('en-US')}</label>
            <label className='light-dark-container'>Household ID: <p className='inline-el light-dark-container' id ="household_id">{newLeads[j]?.household_id[0]}</p></label>
            <label className='light-dark-container'>Phone Number: {newLeads[j]?.home_phone}</label>
            <button type="button" className='button-default green-submit-btn margin-top-10px max-width' onClick={() => acceptLead(document.getElementById('household_id'))}>Accept Lead</button>
          </div>
        ): <div className = "flex-center-column light-dark-container"><img   className = "light-dark-container ten-percent-width" src = "https://i.gifer.com/ZKZg.gif"/></div>}
      </div>

      <div className = "flex-across-space-between"> 
        <OldLeads leads ={leads} i = {i}  acceptLead = {acceptLead}/>
        <FollowUps currentUserData = {currentUserData} />
      </div>
     
    </>
  );
}

export default ChartTime;
