import React, {useRef} from 'react'
import Cookies from 'js-cookie'
function PUP_policy_popup({setCurrentPopup, insurance_companies, POST_POLICY, fetchData}) {
    const POLICY_NUMBER = useRef(null);
    const INSURANCE_COMPANY = useRef(null);
    const EFFECTIVE_DATE = useRef(null);
    const POLICY_STATUS = useRef(null);
    const POLICY_ADDRS = useRef(null);
    const TOTAL_PREM = useRef(null);


    const CREATE_PUP_POLICY_OBJ = () => { 
      if(POLICY_NUMBER.current.value && INSURANCE_COMPANY.current.value && EFFECTIVE_DATE.current.value && POLICY_STATUS.current.value  && POLICY_ADDRS.current.value && TOTAL_PREM.current.value ){ 
          let OBJ = {
              user_id: Cookies.get('userid'),
              policy_type: "PUP",
              policy_number: POLICY_NUMBER.current.value,
              company: INSURANCE_COMPANY.current.value, 
              effective_date: EFFECTIVE_DATE.current.value, 
              status: POLICY_STATUS.current.value, 
              address_line_2: POLICY_ADDRS.current.value,
              monthly_prem: TOTAL_PREM.current.value / 12,  
              total_prem: TOTAL_PREM.current.value,
              items: 1
          }
          return OBJ; 
      }else { 
          alert('Missing input value!');
      }
  }

  const CREATE_NEW_POLICY = async() => { 
    const POLICY_OBJ = CREATE_PUP_POLICY_OBJ();
    if(POLICY_OBJ){ 
        const response = await POST_POLICY(POLICY_OBJ);
        if(response.status === 500){ 
            alert('Policy Number invalid or already exists!')
        }else { 
            fetchData();
            setCurrentPopup(null)
        }

      }
}

  return (
    <div className='background-color-full-dark border-radius-10px popup'  >
    <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick = {() => setCurrentPopup(null)} >❌</button></div>
      <div className='flex-container-el padding-20px'> 
      <h3 className='text-align-left margin-bottom-10'>PUP Policies</h3>
      <label  className='background-color-full-dark text-align-left margin-top-5px '>Policy Number*</label>
      <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width ' autocomplete="off" ref = {POLICY_NUMBER}/>
      <label  className='background-color-full-dark text-align-left margin-top-5px '> Insurance Companies</label>
      <select  className='background-color-full-dark regularTextInput margin-top-5px max-width'  autocomplete="off" ref = {INSURANCE_COMPANY} >
        {insurance_companies && insurance_companies.map(company => ( <option>{company}</option>))}
        </select>
        <label  className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Effective Date*</label>
        <input type = 'date' className='background-color-full-dark regularTextInput margin-top-5px max-width '  autocomplete="off"  ref ={EFFECTIVE_DATE}/>
        <label  className='background-color-full-dark text-align-left margin-top-5px margin-top-5px '>Policy Status*</label>
              <select className='background-color-full-dark regularTextInput margin-top-5px  max-width ' autocomplete="off" ref ={POLICY_STATUS} >
                <option value = "Active">Active</option>
                <option value = "Active">Cancelled</option>
                <option value = "Active">Pending Cancel</option>
                <option value = "Active">Terminated</option>
              </select>
        <label  className='background-color-full-dark text-align-left margin-top-5px margin-top-5px'>Policy Address*</label>
        <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' id = "address_line_2" autocomplete="off" ref ={POLICY_ADDRS}/>
        <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx '>Monthly Prem*</label>
        <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px request-type input max-width' placeholder="$0.00"  autocomplete="off" ref={TOTAL_PREM}/>
   
      <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width" onClick={() => CREATE_NEW_POLICY()}>Add new policy</button>
    </div>
  </div>
  )
}

export default PUP_policy_popup
