import {React, useRef} from 'react'
import Cookies from 'js-cookie';

function NewDrivers({setCurrentPopup, api, currentHouseholdId,  getVehicles}) {

    const first_name = useRef();
    const last_name = useRef();
    const relation = useRef();
    const dateOfBirth = useRef(); 
    const gender = useRef();
    
    const POST_NEW_DRIVER_DATA = async(driverObj) => { 
        console.log(driverObj)
        if(Cookies.get('jwt')){ 
            const response = await fetch(`${api}/api/household/${currentHouseholdId}/addDriver`,{ 
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `${Cookies.get('jwt')}`
                },
                body:JSON.stringify({ 
                            user_id : driverObj.user_id,
                            relation : driverObj.relation,
                            firstname : driverObj.firstname,
                            lastname : driverObj.lastname,
                            relation : driverObj.relation,
                            dateOfBirth : driverObj.dateOfBirth,
                            gender : driverObj.gender
                })
            })
            return response;
         } 
         else { 
            window.location.href = '/';
         }
    }
    

    const CREATE_DRIVER_DATA_OBJ = () => { 
        if(Cookies.get('userid')){ 

        
            if(first_name.current.value && last_name.current.value && relation.current.value && dateOfBirth.current.value && gender.current.value){
                let driverObj = { 
                        user_id : Cookies.get('userid'),
                        relation : relation.current.value,
                        firstname : first_name.current.value,
                        lastname : last_name.current.value,
                        relation : relation.current.value,
                        dateOfBirth : dateOfBirth.current.value,
                        gender : gender.current.value
                }
                return driverObj;
            }else { 
                return alert('Missing an input field!');
            }
        }  else { 
            alert('User ID is not found!');
        }
    }

    const CREATE_NEW_DRIVER_BTN = async() => { 

        const obj = CREATE_DRIVER_DATA_OBJ();
        console.log(obj)
       
        if(obj){ 
            const response = await POST_NEW_DRIVER_DATA(obj);
            if(response.status === 500){ 
                alert('Internal Server Error')
            }else if(response.status === 200){ 
            getVehicles();
            setCurrentPopup(null);
            }
        }
    }

  return (
    <> 
    <div className='background-color-full-dark border-radius-10px popup ' id = "add-driver-crud-ops"  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)}  >❌</button></div>
            <div className='flex-container-el padding-20px'> 
                <h3 className='text-align-left'> Add New driver</h3>
                <label for = "firstname" className='background-color-full-dark text-align-left  '>First name</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width' autocomplete="off" ref = {first_name}/>
                <label for = "lastname" className='background-color-full-dark text-align-left margin-top-10px '>Last Name</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'  autocomplete="off" ref = {last_name}/>
                <label for = "relation" className='background-color-full-dark text-align-left margin-top-10px'>Relation</label>
                <select className='new-driver-input' id = "relation" ref = {relation}> 
                    <option>Self</option>
                    <option>Son</option>
                    <option>Mother</option>
                    <option>Father</option>
                    <option>Brother</option>
                    <option>Sister</option>
                    <option>Grandmother</option>
                    <option>Grandfather</option>
                    <option>Spouse</option>
                    <option>Daughter</option>
                    <option>Grandchild</option>
                </select>
                <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Date of Birth</label>
                <input type = 'date' className='background-color-full-dark regularTextInput margin-top-5px new-driver-input max-width'  autocomplete="off" ref = {dateOfBirth}/>
                <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Gender</label>
                <select  className=' background-color-full-dark regularTextInput margin-top-5px new-driver-input input max-width'  autocomplete="off" ref = {gender}>
                    <option>Male</option>
                    <option>Female</option>
                </select>
                <button type = "button" class = "button-default green-submit-btn margin-top-20px max-width " id = "add=driver-submit-btn" onClick = {()=> CREATE_NEW_DRIVER_BTN()}>Add New Driver</button>
        </div>
       </div>
    </>
      
   
  )
}

export default NewDrivers
