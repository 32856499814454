import React from 'react'

function HouseholdController({setCurrentPopup}) {
    


  return (
   <>
   <div className='background-color-full-dark border-radius-10px  ' id = "household-driver-crud-ops" >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)} >❌</button></div>
            <div className='flex-container-el'> 
            <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(1)}>New Household</button>
            <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(2)}>New Driver</button>
            <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick={() => setCurrentPopup(3)}>Add Vehicle</button>
            <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick= {() => setCurrentPopup(4)}>Change Status Details</button>
            <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick= {() => setCurrentPopup(6)}>Edit / Delete Driver</button>
            <button type = "button" className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width' onClick= {() => setCurrentPopup(7)}>Edit/ Delete Vehicle</button>
        </div>
    </div>
 
   </>
      
    
  )
}

export default HouseholdController
