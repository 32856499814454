import React from 'react'

function OldLeads({leads, i , acceptLead}) {
  return (
   
    <div className='full-width text-align-center'>
    
      {leads ? leads.length > 0 && (
          <div className="flex-column-no-ho-centered light-dark-container text-align-center">
             <h2 className='light-dark-container'>Old Leads</h2> 
            <label className='light-dark-container'>Address: {leads[i]?.address_line_one + ", " + leads[i]?.address_line_two + ", " + leads[i]?.city + ", " + leads[i]?.zip_code}</label>
            <label className='light-dark-container'>Date created: {new Date(leads[i]?.date_created).toLocaleString('en-US')}</label>
            <label className='light-dark-container'>Household ID: <p className='inline-el light-dark-container' id ="household_id">{leads[i]?.household_id[0]}</p></label>
            <label className='light-dark-container'>Phone Number: {leads[i].home_phone}</label>
            <button type="button" className='button-default green-submit-btn margin-top-10px max-width' onClick={() => acceptLead(document.getElementById('household_id'))}>Accept Lead</button>
          </div>
        ): <div className = "flex-center-column light-dark-container"><img   className = "light-dark-container ten-percent-width" src = "https://i.gifer.com/ZKZg.gif"/></div>}
    </div>
  )
}

export default OldLeads
