import React from 'react';
import {useEffect, useState} from 'react';
import Coverages from './Coverages.js'
import Cookies from 'js-cookie';
import SelectCoverageType from './coverages_popups/SelectCoverageType.js';
import AddAutoCoverage from './coverages_popups/AddAutoCoverage.js';
import AddMotorCoverage from './coverages_popups/AddMotorCoverage.js';
import AddJewelryCoverage from './coverages_popups/AddJewelryCoverage.js';
import AddHomeCoverage from './coverages_popups/AddHomeCoverage.js';
import AddRentalCoverage from './coverages_popups/AddRentalCoverage.js';
import PUP_policy_popup from './coverages_popups/PUPCoverage.js'

let api = require('./apiConfig');
api = api.api;

const jwt = Cookies.get('jwt');


function Policies({togglePopUpById, fetchData, policies}) {
   
    const [fetchedCoverages, setFetchedCoverages] = useState();
    const [currentCoveragePopup, setCurrentCoveragePopup] = useState(null);

    useEffect(() => { 
       
           


        fetchData();
   },[])
    
  


    const  fetchCoverages = async (policyType, policyId) => { 

        const response = await fetch(`${api}/api/policy/${policyId}/coverages?type=${policyType}`)
        const data = await response.json();
        setFetchedCoverages(data);
        

        
    }    
const sendCoverageData = async(dataObj) => { 
 
  const response = await fetch(`${api}/api/policy/${dataObj.belong_to_policy}/coverages?type=${dataObj.type}`, { 
    method : "POST", 

    headers:{ 
      Authorization: jwt,
      'Content-Type': 'application/json'
  }, 
    body: JSON.stringify(dataObj)
  })
  
  return response;
 

}



const deletePolicy = async(policy_id, policy_type) => { 

  
  const response = await fetch(`${api}/api/policy?policy_id=${policy_id}&policy_type=${policy_type}`, { 
    method : "DELETE", 
    headere: { 
      Authorization : jwt
    }
  })
  if(!response.ok){ 
    alert("Error deleting"); 
  }else { 
    fetchData();
  }
}

const popups = [ 
  <SelectCoverageType  setCurrentCoveragePopup  = {setCurrentCoveragePopup} />, 
  <AddAutoCoverage setCurrentCoveragePopup  = {setCurrentCoveragePopup} policies={policies} sendCoverageData={sendCoverageData} fetchData= {fetchData} fetchCoverages={fetchCoverages} />, 
  <AddMotorCoverage setCurrentCoveragePopup  = {setCurrentCoveragePopup} sendCoverageData={sendCoverageData}  policies={policies} fetchCoverages={fetchCoverages} />, 
  <AddJewelryCoverage setCurrentCoveragePopup  = {setCurrentCoveragePopup} sendCoverageData={sendCoverageData}  policies={policies} fetchCoverages={fetchCoverages} />, 
  <AddHomeCoverage setCurrentCoveragePopup  = {setCurrentCoveragePopup} sendCoverageData={sendCoverageData}  policies={policies} fetchCoverages={fetchCoverages} />, 
  <AddRentalCoverage  setCurrentCoveragePopup  = {setCurrentCoveragePopup} sendCoverageData={sendCoverageData}  policies={policies} fetchCoverages={fetchCoverages} />,
  <PUP_policy_popup  setCurrentCoveragePopup  = {setCurrentCoveragePopup} sendCoverageData={sendCoverageData}  policies={policies} fetchCoverages={fetchCoverages} />
]




  return (
    <>
      
      <div className='background-color-full-dark display-flex'>
      <div className='flex-column-no-ho-centered flex-basis-400px overflow-wrapper '>
            {policies ? policies.map((policy) => (
            
            <div className=' background-color-full-dark flex-column-no-ho-centered light-hover padding-5px border-bottom  ' onClick={()=> fetchCoverages(policy.policy_type, policy.policy_id)}>
                <label htmlFor='policy_id' className='hover-cursor-pointer'>Policy Number: {policy.policy_id}</label>
                <label htmlFor='policy_id' className='hover-cursor-pointer'>Policy Type: {policy.policy_type}</label>
                <label htmlFor='policy_id' className='hover-cursor-pointer'>Status: {policy.status}</label>
                <label htmlFor='policy_id' className='hover-cursor-pointer'>Effective Date: {new Date(policy.effective_date).toLocaleDateString('en-US')}</label>
                <label htmlFor='policy_id' className='hover-cursor-pointer'>Company: {policy.company}</label>
                <label htmlFor='policy_id' className='hover-cursor-pointer'>Total Premium: ${policy.total_prem}</label>
                <label htmlFor= 'policy_id' className ='hover-cursor-pointer color-red' onClick={() => deletePolicy(policy.policy_id, policy.policy_type)}>🗑️Delete</label>
            </div>
            
             ))  : null }
    </div>
        <div className='flex-container-no-vert-center  overflow-wrapper'>
        <h3 className='text-align-left color-orange'> </h3>
             <Coverages togglePopUpById = {togglePopUpById} 
                        fetchedCoverages = {fetchedCoverages}
                        api = {api} 
                        setCurrentCoveragePopup = {setCurrentCoveragePopup}
                        />
          </div>
      </div>
      {popups[currentCoveragePopup]}
  
  



    </>
  );
}

export default Policies;
