import React from 'react'
import Cookies from 'js-cookie'

function View_ticket({ticketData, fetchTicket, api, currentUserData}) {

    const updateTicketStatus = async(ticket_id) => { 
        
        const response = await fetch(`${api}/api/ticket`, { 
            method: "PUT", 
            headers : { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ticket_id})
        })
        if(!response.ok){ 
            alert("error updating ticket status");
        }else { 
            fetchTicket();
        }
    }

    const deleteTickets = async(ticket_id) => { 
        const response = await fetch(`${api}/api/ticket`, { 
            method: "DELETE", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({ticket_id})
        })
        if(!response.ok){ 
            alert("error updating ticket status");
        }else { 
            fetchTicket();
        }
    }

  return (
    <>
    
    <div className='width-100-percent padding-10px-around  bg-primary'>
        <h2 className='padding-10px-around'> My Tickets</h2>
        <div className='flex space-between  width-100-percent' name = 'main-div '>
        <div className='flex padding-10px-around width-50-percent flex-direction-column' name = 'pending'> 
        <h3>Pending</h3>
            {currentUserData && ticketData && ticketData.map(ticket => (
                (currentUserData[0].role === "Agent" || currentUserData[0].role === "Supervisor") ? 
                (ticket.status === 'pending' && ticket.createdBy === Cookies.get('userid') && (
                    <div className='ticket-pending shadow flex-direction-column flex padding-10px-around' key={ticket.ticket_id}>
                        <label><b>{ticket.name}:</b> {ticket.email}</label>
                        <label><b>Catergory:</b> {ticket.category}</label>
                        <label className='color-red'><b>Description:</b> {ticket.description}</label>
                        
                    </div>
                )) : 
                (ticket.status === 'pending' &&  (
                    <div className='ticket-pending shadow flex-direction-column flex padding-10px-around' key={ticket.ticket_id}>
                        <label><b>{ticket.name}:</b> {ticket.email}</label>
                        <label><b>Catergory:</b> {ticket.category}</label>
                        <label className='color-red'><b>Description:</b> {ticket.description}</label>
                        <button type="button" className='button-1 margin-top-10px' onClick={() => updateTicketStatus(ticket.ticket_id)}>Mark as Completed</button>
                    </div> ))
            ))}
        </div>
        
         


            <div className='flex padding-10px-around width-50-percent flex-direction-column' name = 'completed'>
                    <h3>Completed</h3>
                    {ticketData && ticketData.map(ticket =>(
                        ticket.status  ==='completed' && (
                        <div className='ticket-pending shadow flex-direction-column flex padding-10px-around'>
                        <label><b className='color-orange'>{ticket.name}</b></label>
                        <label>Catergory: {ticket.category}</label>
                        <label>Description: {ticket.description}</label>
                        { currentUserData[0].role === "Agent" || currentUserData[0].role === "Supervisor" ?
                        (null ) 
                        : 
                        (<b className='hover-cursor-pointer'  onClick={() => deleteTickets(ticket.ticket_id)}>🗑️Delete </b> )}
                        
                    </div>
                     )))}
                    
                    

            </div>
        </div>
     </div>
    
    </>
  )
}

export default View_ticket
