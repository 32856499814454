import React, { useEffect, useState, useRef } from 'react';
import HouseholdController from './middle_popups/HouseholdController';
import Cookies from 'js-cookie';
import DriverCard from './DriverCard';
import TabSection from './tab-section';
import NewHouseHolds from './middle_popups/NewHouseHolds';
import NewDrivers from './middle_popups/NewDrivers';
import NewVehicles from './middle_popups/NewVehicles';
import QuotePopup from './middle_popups/QuotePopup';
import NewStatus from './middle_popups/NewStatus';
import EditDriver from './middle_popups/EditDriver';
import EditVehicle from './middle_popups/EditVehicle';
import NewTasks from './middle_popups/NewTasks';
function MiddleSide({currentUserData, api, allUserData, setCurrentHouseholdId, currentHouseholdId, userIdAndFullName, getTasks, setRecentCustomers}) {

  
 

  const [householdData, setHouseholdData] = useState(null);
  const [driverData, setDriverData] = useState(null);
  const [vehicleData, setVehicleData] = useState([]);
  const [slide, setSlide] = useState(null);
  const [page, setPage] = useState(1);
  const [activeComponentIndex, setActiveComponentIndex] = useState(0);
  const jwtToken = Cookies.get('jwt');

  const [currentPopup, setCurrentPopup] = useState(null);



  const getVehicles = () => { 

   
    if (jwtToken && currentHouseholdId) {
      
      fetch(`${api}/api/household/drivers?id=${currentHouseholdId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${jwtToken}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => { 
        //Set recent customer to show previous customers.
        setRecentCustomers(oldArray => [...oldArray, data[0]]);
        
        //Clear the state before loop
        setVehicleData([]);
        Promise.all(data.map(driver => {
          return fetch(`${api}/api/driver/${driver.drivers_id}/vehicles`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${jwtToken}`
            }
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(vehicleData => {
          //Clear prevState 
           
          //Vehicle Data contains an array in an array
            if(vehicleData[0]?.length !== 0 ){ 
              
              //When only one driver has vehicles
            if(vehicleData[0].length >=2){ 
              //Add each object to the state which is an array because when multiple drivers have vehicles the VehicleData is seperate arrays/objs instead of one whole one.
             vehicleData[0].forEach(vehicle => { 
              
                setVehicleData(prevStates => [...prevStates, vehicle]);
              })
              
            }else { 
              //when multiple drivers have vehicles
              setVehicleData(prevStates => [...prevStates,vehicleData[0][0]]);
             
            }
             
            }
            
            // Merge driver and vehicle data
            return { ...driver, vehicle: vehicleData[0] };
          });
        }))
        .then(mergedData => {
          // Update driverData with merged data
          setDriverData(mergedData);
        })
        .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
        });
      } 
      
    
    )
      .catch(error => {
        // Handle any errors
        console.error('There was a problem with your fetch operation:', error);
      });
    } else {
      console.error('jwtToken or currentHouseholdId is missing');
    }
  }



  const setLatestHousehold = async() => { 
    const response = await fetch(`${api}/api/household/latest?page=1`);
    const data = await response.json();
    setCurrentHouseholdId(data[0].household_id);
  
  }

  function formatPhoneNumber(phoneNumber) {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
    const formattedPhoneNumber = ` (${cleanedPhoneNumber.substring(0, 3)}) (${cleanedPhoneNumber.substring(3, 6)}) (${cleanedPhoneNumber.substring(6)})`;
    return formattedPhoneNumber;
  }

  



  const updateDrivers = async(currentHouseholdId) => { 
    fetch(`${api}/api/household/drivers?id=${currentHouseholdId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${jwtToken}`
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setDriverData(data);
      
     
    })
    .catch(error => {
      // Handle any errors
      console.error('There was a problem with your fetch operation:', error);
    });
  
  }


    

const togglePopUpByEl = (element) => { 
  element.hidden = !element.hidden;
}




  useEffect(() => {


    if (jwtToken && currentHouseholdId) {
      fetch(`${api}/api/household?id=${currentHouseholdId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${jwtToken}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        
        setHouseholdData(data[0]);
      })
      .catch(error => {
        // Handle any errors
        console.error('There was a problem with your fetch operation:', error);
      });
    } else {
      console.error('jwtToken or currentHouseholdId is missing');
    }
  }, [jwtToken, currentHouseholdId]);

  useEffect(() => {

    
    if (jwtToken && currentHouseholdId) {
      fetch(`${api}/api/household/drivers?id=${currentHouseholdId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${jwtToken}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setDriverData(data);
        
       
      })
      .catch(error => {
        // Handle any errors
        console.error('There was a problem with your fetch operation:', error);
      });
    } else {
      console.error('jwtToken or currentHouseholdId is missing');
    }
  }, [jwtToken, currentHouseholdId]);


  useEffect(() => {
    
    getVehicles();
  },[jwtToken, currentHouseholdId]);


  const changeHousehold = async(event) => { 
    
 
   
   if(event.target.id === "next-household"){  
    
    let seeIfPageValid = page + 1;
    
    if(currentUserData[0]?.role === 'Admin' ||currentUserData[0]?.role === 'Supervisor' ){ 
     
    const response = await fetch(`${api}/api/household/latest?page=${seeIfPageValid}`);
    
    const data = await response.json();
    if(data.length !== 0 ){ 
      
      
     
      setCurrentHouseholdId(data[0]?.household_id);
      
     
      setActiveComponentIndex(0)
      Cookies.set('currentHouseholdId',data[0]?.household_id);
      setPage(seeIfPageValid);
      }else { 
        alert('This is your oldest added household!');
      }
     


    }else { 

      let seeIfPageValid = page + 1;
      const response = await fetch(`${api}/api/household/belong?page=${seeIfPageValid}&user_id=${currentUserData[0]?.id}`);
      const data = await response.json();

      if(data.length !== 0 ){ 
      
        setPage(seeIfPageValid)
        
        setCurrentHouseholdId(data[0]?.household_id);

        setActiveComponentIndex(0)
        Cookies.set('currentHouseholdId',data[0]?.household_id);
        }else { 
          alert('No next households!');
        }
       
    }
  }

    if(event.target.id === "previous-household"){  

      if( page > 1){ 
        let seeIfPageValid = page - 1;
      
      if(currentUserData[0].role === 'Admin'){ 
       
      const response = await fetch(`${api}/api/household/latest?page=${seeIfPageValid}`);
      
      const data = await response.json();
      if(data.length !== 0 ){ 
        
        setPage(seeIfPageValid);
        setCurrentHouseholdId(data[0].household_id);
        setActiveComponentIndex(0)
        Cookies.set('currentHouseholdId',data[0].household_id);
        }else { 
          
        }
       
  
  
      }else { 
        const response = await fetch(`${api}/api/household/belong?page=${seeIfPageValid}&user_id=${currentUserData[0]?.id}`);
        const data = await response.json();
  
        if(data.length !== 0 ){ 
        
          setPage(seeIfPageValid);
          setCurrentHouseholdId(data[0]?.household_id);
          setActiveComponentIndex(0)
          Cookies.set('currentHouseholdId',data[0].household_id);
          }else { 
            
          }
         
      }
    

  }else { 
    alert('This is your newest added household!');
  }
  }
    return;

  }

  const assignUser = async(user_id) => { 
    let id = user_id.value;
    const response = await fetch(`${api}/api/household/assign?user_id=${id}&household_id=${currentHouseholdId}`, { 
      method: "POST",
      headers: { 
        Authorization : jwtToken
      }
    })
  
    if(!response.ok){ 
      alert("Error assigning user");
     
    }else { 
      window.location.reload();
    }
    
  }

    
  
    const slideLeft = () => {
     
      setSlide('slide-left');
    };

    const slideRight = () => { 
      setSlide('slide-right');
    }
  


    const currentCustomer = [
      "New",
      "Quoted",
      "Quoted Hot",
      "Called, No Contact",
      "Called, LVM",
      "BAD NUMBER",
      "Do Not Call",
      "Not Elegible",
      "Price is too High",
      "Policy in Force",
      "Cancelled",
      "Terminated"
    ];

    const prospect = [
      "New",
      "Quoted",
      "Quoted Hot",
      "Called, No Contact",
      "Called, LVM",
      "BAD NUMBER",
      "Do Not Call",
      "Not Elegible",
      "Price is too High", 
      "Contacted  Quoted & Call Back",
      "Not Interested",
      "Try again in 6 months",
      "Followed Up"
    ];

    const formerCustomer = [ 
      "New",
      "Cancelled", 
      "Terminated"
    ]

    const updateStatusDescription = async(desc) => {
      
        let status_desc = desc.value; 
        //If these criterias are met the agents must submit a quote to the notes.
        if(status_desc === "Quoted" || status_desc === "Quoted Hot" || status_desc === "Price is too High" ){
          

          const response = await fetch(`${api}/api/household/status?household_id=${currentHouseholdId}`, { 
            method: "POST",
            body : JSON.stringify({ 'status_description': status_desc }),
            headers : { 
              'Content-Type': 'application/json',
            }
          })
          if(!response.ok){ 
            alert("Error updating status description");
          }else{ 
            //When the response comes back ok display the quotes form
            setCurrentPopup(5);
          }
          
        } else { 
          //For when they dont select one of the criterias to require quoting.
          const response = await fetch(`${api}/api/household/status?household_id=${currentHouseholdId}`, { 
            method: "POST",
            body : JSON.stringify({ 'status_description': status_desc }),
            headers : { 
              'Content-Type': 'application/json',
            }
          })
  
          if(!response.ok){ 
            alert("Error updating status description");
          }else { 
            window.location.reload();
          }
  

        }

        


        
    }
    
    const copyToClipboard = (text) => { 
      navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  
    }
   


    const popups = [ 
      <HouseholdController setCurrentPopup = {setCurrentPopup}  />, //Household Controller
      <NewHouseHolds  setCurrentPopup = {setCurrentPopup}  api = {api} setLatestHousehold = {setLatestHousehold}/>,  //Adding new household
      <NewDrivers setCurrentPopup={setCurrentPopup}  api = {api}  currentHouseholdId={currentHouseholdId} updateDrivers = {updateDrivers} getVehicles = {getVehicles}/>,  //Adding new driver
      <NewVehicles setCurrentPopup={setCurrentPopup} driverData = {driverData}  api = {api}  getVehicles = {getVehicles}/>, //Adding new vehicle. Passing driver data to display driver name when adding vehicle.
      <NewStatus api = {api} currentHouseholdId={currentHouseholdId}  setCurrentPopup={setCurrentPopup} householdData={householdData} currentCustomer = {currentCustomer} prospect = {prospect} formerCustomer = {formerCustomer}  />,
      <QuotePopup api = {api} setCurrentPopup={setCurrentPopup} currentHouseholdId={currentHouseholdId} />, 
      <EditDriver  setCurrentPopup={setCurrentPopup} driverData={driverData} api = {api} updateDrivers={updateDrivers} getVehicles = {getVehicles} />, 
      <EditVehicle setCurrentPopup={setCurrentPopup} driverData={driverData} api = {api} vehicleData = {vehicleData}  getVehicles = {getVehicles} />, 
      <NewTasks setCurrentPopup={setCurrentPopup} api = {api} getTasks={getTasks} />
    ]
    const address = [
      householdData?.address_line_two,
      householdData?.address_line_one,
      householdData?.city,
      householdData?.state
    ].filter(Boolean).join(', ');
    
  
    





  return (
    
    <>
  
    
    {householdData && currentUserData && ( 
   
      <div className="middle-section-top light-dark-container ">
     
        <div className=' text-align-left  align-items-center light-dark-container display-flex   '>
        
        <h4 className='light-dark-container ' id = "household-status">{householdData.status + ' '}    
        <select className=' background-color-full-dark  margin-top-5px new-household-input padding-10px' id = "top-level-status-desc" value = {householdData.status_description} autocomplete="off" onChange={() => updateStatusDescription(document.getElementById('top-level-status-desc'))}>
         { householdData && (householdData.status === 'Current Customer') ? currentCustomer.map(options => ( 
          <option value = {options} >{options}</option>
         ))
         : 
         householdData && (householdData.status === "Prospect") ?  prospect.map( options => ( 
          <option value = {options}>{options}</option>
         )) 
         : 
         householdData && (householdData.status.toLowerCase() === "former customer") ?  formerCustomer.map( options => ( 
          <option value = {options}>{options}</option>
         ))
         : null
        }
        </select>
          
          </h4>
     
         {householdData && ( 
          
         householdData && currentUserData[0]?.role === "Admin" || currentUserData[0]?.role === "Supervisor" ?  <h4 className=' hover-red light-dark-container' title ="Assigned Already" >Assigned To  
          <select className='background-color-full-dark padding-10px margin-top-5px new-household-input' id = "top-level-assign-user" onChange={() => assignUser(document.getElementById('top-level-assign-user'))} >
          <option value="" selected disabled>{allUserData && householdData.user_id ? allUserData.find(user => user.id === householdData.user_id)?.full_name : "Unassigned"}</option>
            {allUserData && allUserData.map(user => (
              
              <option value = {user.id || "N/A"}>{user.full_name}</option>
        
            )) }
            
         </select>
         </h4> 
         : null )}

         <button type = "button " className='green-submit-btn' onClick = {() => setCurrentPopup(8)}>New Task</button>
         <div className='padding-left-5px'> 
            <span class = "light-dark-background ">Created On: {householdData?.date_created && new Date(householdData.date_created).toLocaleDateString()}</span>
         </div>
  
         </div>
 

        <div className="driver-container flex-across light-dark-container  height-n-300px  ">
         
        <div className= {` light-dark-container overflow-hidden  `}>
        <div className={`display-flex light-dark-container ${slide}  `}>
        {driverData && driverData.map(driver => (
            <DriverCard
              key={driver.drivers_id}
              id={driver.drivers_id}
              firstname={driver.firstname}
              lastname={driver.lastname}
              phone_number={driver.phone_number}
              relation={driver.relation}
              gender={driver.gender}
              dateOfBirth = {driver.dateOfBirth}
              vehicleData = {driver.vehicle && driver.vehicle[0] ? driver.vehicle : 'No car data'}
              slide={slide}
              togglePopUpByEl={togglePopUpByEl}
              setCurrentPopup = {setCurrentPopup}

              // Add other props as needed
            />
          ))}
        </div>
          </div>

         

<div className="householdCrudOps light-dark-background flex-container-el ">
            <button type="button" className='popup-tab-buttons margin-left-10 hover-red margin-top-10px' onClick={() => setCurrentPopup(0)}> <b className='color-orange font-size-large hover-red' > + </b></button>
            <button type="button" className='popup-tab-buttons margin-left-10 margin-top-10px hover-red'> <b className='color-orange font-size-large hover-red ' onClick={()=> slideRight()}> &lt;- </b></button>
            <button type="button" className='popup-tab-buttons margin-left-10 margin-top-10px hover-red'> <b className='color-orange font-size-large hover-red ' onClick={()=> slideLeft()}> -&gt; </b></button>
            
          </div>
        </div>
      </div>
          
)}





    
        {householdData ? (
          


      <div className="middle-section-middle light-dark-container margin-top-50">
        <h3 className="light-dark-container">Household Contact Information:</h3>
        <label className="light-dark-container">
          <b className="light-dark-background color-orange cursor-pointer " title = "Click To Copy"  onClick={() => copyToClipboard(householdData.home_phone) }>Home Phone:</b> <a href={'tel:' + householdData.home_phone}>{formatPhoneNumber(householdData.home_phone)}</a>
        </label>
        <label className="light-dark-container">
          <b className="light-dark-background color-orange cursor-pointer" title = "Click To Copy" onClick={() => copyToClipboard(householdData.email) }>Email:</b> {householdData.email}
        </label>
        <label className="light-dark-container">
          <b className="light-dark-background color-orange cursor-pointer" title = "Click To Copy"  onClick={() => copyToClipboard(address) }>Address:</b>{' '}
          {address}
        </label>
        <label className="light-dark-container">
          <b className="light-dark-background color-orange cursor-pointer" onClick={() => copyToClipboard(householdData.zip_code) } title = "Click To Copy">Zip Code:</b> {householdData.zip_code}
        </label>
        <br />
      </div>
    ) : (
      
      <div className = "flex-center-column light-dark-container"><img   className = "light-dark-container ten-percent-width" src = "https://i.gifer.com/ZKZg.gif"/></div>
    
    )}
       <div className='light-dark-container .flex-across household-control-btns '>
            <button type="button" className=" green-submit-btn" id="previous-household" onClick = {changeHousehold} >Previous Household</button>
            <label className='light-dark-container margin-right-20px'>  ID: {currentHouseholdId}</label>
          <button type="button" className=" green-submit-btn" id="next-household" onClick = { changeHousehold} >Next Household</button>
          </div>
      
      
      <div className="middle-section-middle light-dark-container margin-top-50" >
        <TabSection  vehicleData = {vehicleData}  currentPopup = {currentPopup} setCurrentPopup = {setCurrentPopup} api ={api} activeComponentIndex ={activeComponentIndex} setActiveComponentIndex ={setActiveComponentIndex} currentUserData={currentUserData}  userIdAndFullName = {userIdAndFullName}/>
      </div>
     
   
     { popups && popups[currentPopup]}
  




       
      


      
    </>
  );
}

export default MiddleSide;
