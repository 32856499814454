import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const userid = Cookies.get('userid');
function RightSide({api}) {


  const [recentUwActions, setRecentUwActions] = useState();

  const getUwActions = async() => { 
    const response = await fetch(`${api}/api/uw_action_request?userid=${userid}`);
    const data = await response.json();
    setRecentUwActions(data);
  }


  useEffect(() => { 
    getUwActions();
  }, [])
 


  return (
    <>
    <div className = "light-dark-container">
    <h3 className = "light-dark-container inline-el flex-spacebetween "> Dialer Settings</h3>
    <select id = "select-dialer" className='margin-left-50' >
    </select>
    </div>
    
    <div className = "light-dark-container flex-center flex-center-column " > 
    <input type ="text" className='regularTextInput background-light-container max-width-100'/>
    <div className = "" id = "dialpad"> 
                <button type="button" className = "dialpad-btn">1</button>
                <button type="button" className = "dialpad-btn">2</button>
                <button type="button" className = "dialpad-btn">3</button>
                <button type="button" className = "dialpad-btn">4</button>
                <button type="button" className = "dialpad-btn">5</button>
                <button type="button" className = "dialpad-btn">6</button>
                <button type="button" className = "dialpad-btn" >7</button>
                <button type="button" className = "dialpad-btn">8</button>
                <button type="button" className = "dialpad-btn">9</button>
                <button type="button" className = "dialpad-btn dialpad-btn-0">0</button>
    </div>
    </div>

    <div className = "light-dark-container margin-top-50 stretch-right-side"> 

    <h3 className='light-dark-container overflow'>UW Action Required</h3>


    {recentUwActions && recentUwActions.map((action, index) => (
       index <  8 ? ( 
      <a key={index} href='#' className='light-dark-container block-display' onClick = {()=> {Cookies.set('currentHouseholdId', action.household_id); window.location.reload()}}>
          Uw action #{index + 1}
      </a>
       ) : null
    ))}
   
    </div>


    <div className='light-dark-container margin-top-50 stretch-right-side'>
    <h3 className='light-dark-container'>Text Messages</h3>
    <label for = "sms-content" className = "light-dark-container">From:</label>
    <p id = "sms-content" className='inline-el light-dark-container'> </p>
    
    </div>
     </>
    
  );
}

export default RightSide;